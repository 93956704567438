import { isAndroid, isIOS } from "react-device-detect"

export const SERVER_URL_DEV = "https://apidev.getwooapp.com/woo/api" //'http://172.21.24.232:8080/woo/api'
// export const SERVER_URL_TEST = 'https://apitest.getwooapp.com/woo/api' //'http://api-testing-1816224593.ap-southeast-1.elb.amazonaws.com/woo/api'
//export const SERVER_URL_TEST = "https://api-staging.ukuthanda.com/woo/api"

export const SERVER_URL_TEST = "https://api-prod.ukuthanda.com/woo/api"
// export const SERVER_URL_PROD = 'https://api.getwooapp.com/woo/api'
export const SERVER_URL_PROD = "https://api-prod.ukuthanda.com/woo/api"
export const APP_VERSION = "1.0"
export const DEVICE_TYPE = "WEB"

export const installUrlIOS =
  "https://apps.apple.com/in/app/woo-the-dating-app-women-love/id885397079"
export const installUrlAndroid =
  "https://play.google.com/store/apps/details?id=com.u2opia.woo"
export const privacyUrl = "https://getwooapp.com/privacy-policy.html"

export const imageCroppingServerURL =
  "http://image.luvingtalemobile.com/image-server/api/v3/image/crop/"
export function onClickInstallButton() {
  isIOS ? onClickIOSBadge() : onClickAndroidBadge()
}

export function onClickAndroidBadge() {
  const utm = localStorage.getItem("utm")
  const utmFinal = utm ? encodeURIComponent(utm.replace("?", "")) : ""
  console.log("utmFinal", utmFinal)
  fbq("track", "Install_Click_MWeb")
  //window.gtag("event", "Install_Click_MWeb")
  window.open(`${installUrlAndroid}&referrer=${utmFinal}`, "_blank")
}

export function onClickIOSBadge() {
  const utm = localStorage.getItem("utm")
  console.log("utm value", utm)
  fbq("track", "Install_Click_MWeb")
  //window.gtag("event", "Install_Click_MWeb")
  window.open(`${installUrlIOS}${utm}`, "_blank")
}

export const pageSizeOfGettingChatMessages = 30

// export const kAppId_Applozic = "3d51651ee1e6bfe50b79f5e611cb3fe2e"
// export const kAppId_Applozic = "117b8ce3a660a627d405d8a9bd6cd6d79"
export const kAppId_Applozic = "117b8ce3a660a627d405d8a9bd6cd6d79"

export const PAYTM_STATUS_URL = "https://securegw.paytm.in/order/process" //"https://securegw-stage.paytm.in/order/process"
export const CHANNEL_ID = "WEB"
export const THEME = "merchant"
export const MID = "DOUBLE90844942464137" //"DOUBLE88631309281624"
export const REQUEST_TYPE = "SUBSCRIBE" //"DEFAULT";
export const INDUSTRY_TYPE_ID = "Retail109" //"Retail"
export const WEBSITE = "DOUBLEwap" //"WEBSTAGING"
export const callBackUrl = "/v1/redirect/paytm/woo"
export const SUBS_AMOUNT_TYPE = "VARIABLE"
export const SUBS_ENABLE_RETRY = "1"
export const SUBS_RETRY_COUNT = "500"
export const SUBS_EXPIRY_DATE = "2030-12-31"
export const SUBS_FREQUENCY_UNIT = "DAY"
export const SUBS_PPI_ONLY = "Y"

// export const TELCO_LOGIN_FAIL_URL = "https://tza-staging.ukuthanda.com/login"

export const DOMAIN = window.location.hostname
console.log("config domain", DOMAIN)

export const TELCO_LOGIN_FAIL_URL = `http://${DOMAIN}/login`
console.log("config login url", TELCO_LOGIN_FAIL_URL)

export const CHATEZEE_GATEWAY_URL_TEST = "http://gateway.test.chatezee.com"
export const CHATEZEE_GATEWAY_URL = "http://gw.chatezee.com"

export const SOCKECT_SERVER_URL_TEST = "http://socket.chat.chatezee.com/"
export const SOCKECT_SERVER_URL = "http://socketchat.chatezee.com/"

export const OPERATOR_ID = env.prod ? "EPSN" : "U2OPIADUMMY"
export const SUB_DOMAIN = env.prod ? "prod" : "test"
 